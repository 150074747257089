import React from 'react'
import classNames from 'classnames'

import SvgSprite from 'src/components/SvgSprite'
import NavBrandIdentity from 'src/components/NavBrandIdentity'

import styles from './styles.module.scss'

const classNamesIntor = classNames('container', styles.intro)
const classNamesBrandNavContainer = classNames('container', styles.brandNavContainer)
const classNamesTitle = classNames('container', styles.title)

const BrandIdentity = () => {
    return (
        <section className={styles.brandIdentity} id="brand-identity">
            <h1 className={classNamesTitle}>
                Calaton Studio <span className={styles.break}>Brand Identity</span>
            </h1>
            <div className={styles.brandIdentityContainer}>
                <SvgSprite name="logoGreenCircle" className={styles.logoGreenCircle} />
                <SvgSprite name="web" className={styles.web} />
                <div className={classNamesIntor}>
                    <div className={styles.brandingLogo}>
                        <SvgSprite name="logo" />
                    </div>
                    <h2 className={styles.titleSecond}>
                        To make technology <span className={styles.real}>real</span>
                    </h2>
                    <p className={styles.introTextFirst}>
                        The task was to create an identity for an IT company from scratch, with only
                        the name Calaton available.
                    </p>
                    <p className={styles.introTextSecond}>
                        The task was to create an identity for an IT company from scratch, with only
                        the name Calaton available. The preference was given to a minimalistic
                        modern style with a variable logo.
                    </p>
                </div>
                <div className={styles.brandNav}>
                    <div className={classNamesBrandNavContainer}>
                        <div className={styles.navBrandIdentity}>
                            <NavBrandIdentity />
                        </div>
                        <div className={styles.ideaLamp}></div>
                        <p className={styles.brandNavText}>
                            In the process of work, we were able to find a balance between the
                            seriousness and manufacturability of the IT sphere and a modern bright
                            and playful creative accent.
                        </p>
                        <SvgSprite name="bckgLogoGray" className={styles.bckgLogoGray} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BrandIdentity
