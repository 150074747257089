import React from 'react'
import classNames from 'classnames'

import Picture from 'src/components/Picture'
import uiGuideCalatonMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIGuideCalaton.jpg'
import uiGuideCalatonMobileRetina from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIGuideCalaton@2x.jpg'
import uiGuideCalatonMobileDesktop from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIGuideCalaton.jpg'
import uiGuideCalatonMobileDesktopRetina from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIGuideCalaton@2x.jpg'
import uiButtonsMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIButtons.jpg'
import uiButtonsMobileRetina from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIButtons@2x.jpg'
import uiButtonsMobileDesktop from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIButtons.jpg'
import uiButtonsMobileDesktopRetina from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIButtons@2x.jpg'
import uiIconsMobile from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIIcons.jpg'
import uiIconsMobileRetina from 'src/images/optimized/jpg/cases/calatonStudio/mobile/UIIcons@2x.jpg'
import uiIconsDesktop from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIIcons.jpg'
import uiIconsDesktopRetina from 'src/images/optimized/jpg/cases/calatonStudio/desktop/UIIcons@2x.jpg'

import styles from './styles.module.scss'

const UIKitImages = [
    {
        source: [
            {
                srcSet: uiGuideCalatonMobile,
                retina: uiGuideCalatonMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: uiGuideCalatonMobileDesktop,
                retina: uiGuideCalatonMobileDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: uiGuideCalatonMobileDesktop,
        retina: uiGuideCalatonMobileDesktopRetina,
        alt: 'uikit guide calaton studio',
    },
    {
        source: [
            {
                srcSet: uiIconsMobile,
                retina: uiIconsMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: uiButtonsMobileDesktop,
                retina: uiButtonsMobileDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: uiButtonsMobileDesktop,
        retina: uiButtonsMobileDesktopRetina,
        alt: 'uikit buttons calaton studio',
    },
    {
        source: [
            {
                srcSet: uiButtonsMobile,
                retina: uiButtonsMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: uiIconsDesktop,
                retina: uiIconsDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: uiIconsDesktop,
        retina: uiIconsDesktopRetina,
        alt: 'uikit icons calaton studio',
    },
]

const classNameUiKitContainer = classNames('container', styles.container)
const classNamesTitle = classNames('calaton-studio-branding__part-title', styles.title)
const classNamesPart = classNames('calaton-studio-branding__part', styles.part)

const UiKit = () => {
    return (
        <section className={styles.uikit} id="ui-kit">
            <div className={classNameUiKitContainer}>
                <span className={classNamesPart}>1.5</span>
                <h2 className={classNamesTitle}>Ui kit</h2>
                <p className={styles.text}>
                    All of the above principles and a number of others are collected in a neat
                    structured UI KIT.
                </p>
                <div>
                    {UIKitImages.map((item, i) => (
                        <div className={styles.imgContainer} key={i}>
                            <Picture className={styles.picture} pic={item} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default UiKit
