import React from 'react'
import classNames from 'classnames'

import Picture from 'src/components/Picture'
import docsFrontMobile from 'src/images/optimized/png/CalatonStudioBrandingWeb/docsFrontMobile.png'
import docsFrontMobileRetina from 'src/images/optimized/png/CalatonStudioBrandingWeb/docsFrontMobile@2x.png'
import docsFrontDesktop from 'src/images/optimized/png/CalatonStudioBrandingWeb/docsFrontDesktop.png'
import docsFrontDesktopRetina from 'src/images/optimized/png/CalatonStudioBrandingWeb/docsFrontDesktop@2x.png'
import docsBackMobile from 'src/images/optimized/png/CalatonStudioBrandingWeb/docsBackMobile.png'
import docsBackMobileRetina from 'src/images/optimized/png/CalatonStudioBrandingWeb/docsBackMobile@2x.png'
import docsBackDesktop from 'src/images/optimized/png/CalatonStudioBrandingWeb/docsBackDesktop.png'
import docsBackDesktopRetina from 'src/images/optimized/png/CalatonStudioBrandingWeb/docsBackDesktop@2x.png'
import businessCardMobile from 'src/images/optimized/png/CalatonStudioBrandingWeb/businessCardsMobile.png'
import businessCardMobileRetina from 'src/images/optimized/png/CalatonStudioBrandingWeb/businessCardsMobile@2x.png'
import businessCardDesktop from 'src/images/optimized/png/CalatonStudioBrandingWeb/businessCardsDesktop.png'
import businessCardDesktopRetina from 'src/images/optimized/png/CalatonStudioBrandingWeb/businessCardsDesktop@2x.png'

import styles from './styles.module.scss'

const docs = [
    {
        source: [
            {
                srcSet: docsFrontMobile,
                retina: docsFrontMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: docsFrontDesktop,
                retina: docsFrontDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: docsFrontDesktop,
        retina: docsFrontDesktopRetina,
        alt: 'office materials',
    },
    {
        source: [
            {
                srcSet: docsBackMobile,
                retina: docsBackMobileRetina,
                media: '(max-width: 990px)',
            },
            {
                srcSet: docsBackDesktop,
                retina: docsBackDesktopRetina,
                media: '(min-width: 990px)',
            },
        ],
        src: docsBackDesktop,
        retina: docsBackDesktopRetina,
        alt: 'office materials',
    },
]

const businessCards = {
    source: [
        {
            srcSet: businessCardMobile,
            retina: businessCardMobileRetina,
            media: '(max-width: 990px)',
        },
        {
            srcSet: businessCardDesktop,
            retina: businessCardDesktopRetina,
            media: '(min-width: 990px)',
        },
    ],
    src: businessCardDesktop,
    retina: businessCardDesktopRetina,
    alt: 'office materials',
}

const classNameOfficeMaterialsContainer = classNames('container', styles.container)
const classNamesTitle = classNames('calaton-studio-branding__part-title', styles.title)
const classNamesPart = classNames('calaton-studio-branding__part', styles.part)

const OfficeMaterials = () => {
    return (
        <section className={styles.officeMaterials} id="office-materials">
            <div className={classNameOfficeMaterialsContainer}>
                <span className={classNamesPart}>3.1</span>
                <h2 className={classNamesTitle}>Office materials</h2>
                <p className={styles.text}>
                    Each of the carriers of the style is described in detail in the guideline,
                    taking into account the smallest details.
                </p>
                <div className={styles.docsAndBusinessСards}>
                    <div className={styles.docs}>
                        {docs.map((doc, i) => (
                            <div className={styles.doc} key={i}>
                                <Picture pic={doc} className={styles.picture} />
                            </div>
                        ))}
                    </div>
                    <div className={styles.businessCards}>
                        <div className={styles.businessCards}>
                            <Picture pic={businessCards} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OfficeMaterials
