import React from 'react'

import BaseContainer from 'src/components/BaseContainer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import CalatonStudioBrandingWebCase from 'src/components/CalatonStudioBrandingWebCase'
import Meta from '../../components/Meta'

const metaData = {
    title: 'Calaton Studio Brand Identity',
    description: 'Calaton Studio we have many cases with completed works',
    keywords: 'Calaton Studio, cases',
}

const CalatonStudioBranding = () => {
    return (
        <BaseContainer>
            <Meta content={metaData} />
            <Header />
            <CalatonStudioBrandingWebCase />
            <Footer />
        </BaseContainer>
    )
}

export default CalatonStudioBranding
