import React from 'react'

import BrandIdentity from './BrandIdentity'
import BrandSystem from './BrandSystem'
import ColourPaletteBrandingStudio from './ColourPaletteBrandingStudio'
import Typography from './Typography'
import Graphics from './Graphics'
import UiKit from './UiKit'
import WebSolutions from './WebSolutions'
import OfficeMaterials from './OfficeMaterials'
import OtherCases from '../OtherCases'
import LetsTalk from '../LetsTalk'
import brandingBrief from '../../docs/Branding-brief.pdf'

import styles from './styles.module.scss'

const CalatonStudioBrandingWebCase = () => {
    return (
        <main className={styles.calatonStudioBrandingWebCase}>
            <BrandIdentity />
            <BrandSystem />
            <ColourPaletteBrandingStudio />
            <Typography />
            <Graphics />
            <UiKit />
            <WebSolutions />
            <OfficeMaterials />
            <OtherCases filter="branding" />
            <LetsTalk brief={{ name: 'Branding-brief', file: brandingBrief }} />
        </main>
    )
}

export default CalatonStudioBrandingWebCase
