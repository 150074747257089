import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

const classNameTypographyContainer = classNames('container', styles.container)
const classNamesTitle = classNames('calaton-studio-branding__part-title', styles.title)

const getSymbols = () => {
    return (
        <>
            A B C D E F G H I J K L M N O P Q R S T U V W X Y Z <br />
            a b c d e f g h i j k l m n o p q r s t u v w x y z <br />1 2 3 4 5 6 7 8 9 0
        </>
    )
}

const Typography = () => {
    return (
        <section className={styles.typography} id="typograhy">
            <div className={classNameTypographyContainer}>
                <span className="calaton-studio-branding__part">1.3</span>
                <h2 className={classNamesTitle}>Typography</h2>
                <div className={styles.content}>
                    <div className={styles.top}>
                        <p className={styles.brandSystemText}>
                            The signature font for the core set is the Roboto family of headsets.
                            The Roboto font has several variations: light, regular, medium, bold.
                            This font has an appearance of efficiency and forwardness and is
                            universal both for printing and for web resources.
                        </p>
                        <p>
                            To give individuality as well as playfulness to the general mood of
                            visual communication, it was decided to use the Rozha One fonts family
                            wich is also a very high contrast Open Source font.
                        </p>
                    </div>
                    <div className={styles.bottom}>
                        <div className={styles.roboto}>
                            <p>Roboto</p>
                            <p>{getSymbols()}</p>
                        </div>
                        <div className={styles.rozhaOne}>
                            <p>Rozha One</p>
                            <p>{getSymbols()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Typography
